import request from '../utils/request';

/**
 * 中考计算器
 * @param query
 * @returns {AxiosPromise}
 */
export const getCalculator = (query) => {
    return request({
        url: '/cal/calculator/page',
        method: 'GET',
        params: query
    });
};

export const insert = (calculator) => {
    return request({
        url: '/cal/calculator/insert',
        method: 'POST',
        params: calculator
    });
};
export const deleteCalculator = (id) => {
    return request({
        url: '/cal/calculator/delete',
        method: 'GET',
        params: {id}
    });
};
/**
 * 导入数据
 * @param query
 * @returns {AxiosPromise}
 */
export const insertData = (file) => {
    console.log(file)
    return request({
        // url: '/sys/sportTest/importFile',
        url: '/cal/calculator/import',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};

export const selectRegion = () => {
    return request({
        url: '/cal/calculator/selectRegion',
        method: 'GET'
    });
};