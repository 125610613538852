<template>
  <div>
    <div style="padding: 20px">
    <div class="handle-box">
        <el-button type="primary" class="mr10" @click="addModule = true">新增</el-button>
        <el-select v-model="query.regionId" placeholder="城市" class="handle-select mr10">
          <el-option @click="selectCity1(index)" v-for="(item,index) in regionList" :key="index"
                     :label="item.regionName" :value="item.regionId"></el-option>
        </el-select>
        <el-select v-model="query.sportTypeId" placeholder="类型" class="handle-select mr10">
          <el-option v-for="(item,index) in sportTypeList" :key="index"
                     :label="item.sportTypeName" :value="item.sportTypeId"></el-option>
        </el-select>
        <el-button @click="getData()">搜索</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button class="shadow animated bounce" style="margin-left: 10px;"
                   @click="Cal=true">导入数据
        </el-button>
      </div>
      <!--table展示数据-->
      <el-table
          height="535px"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :row-style="{height:0+'px'}"
          :cell-style="{padding:0+'px'}"
      >
        <el-table-column
            width="210"
            show-overflow-tooltip="true"
            prop="regionName"
            label="城市名称"
        ></el-table-column>
        <el-table-column
            width="210"
            show-overflow-tooltip="true"
            prop="sportTypeName"
            label="项目名称"
        ></el-table-column>
        <el-table-column
            width="100"
            show-overflow-tooltip="true"
            prop="sex"
            label="性别"
        ></el-table-column>
        <el-table-column prop="score" width="240" label="成绩">
        </el-table-column>
        <el-table-column
            width="200"
            show-overflow-tooltip="true"
            prop="results"
            label="得分">
        </el-table-column>
        <el-table-column
            width="200"
            show-overflow-tooltip="true"
            prop="note"
            label="提示语">
        </el-table-column>
        <el-table-column
            label="操作"
            align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <el-dialog v-model="Cal" width="500px">
        <div style="display: flex;justify-content: center">
          <el-upload drag :limit=limitNum :auto-upload="false" :on-remove="handleRemove"
                     :on-change="fileChange" :on-exceed="exceedFile" :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
        <div style="display: flex;justify-content: center;padding-top: 30px">
          <el-button :size="small" type="primary" @click="uploadFile">导入数据</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="添加" v-model="addModule" width="800px">
      <el-form style="margin-right: 50px" :model="insertForm" :rules="rules" ref="insertForm" label-width="120px"
               class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="regionId" label="城市">
              <el-select v-model="insertForm.regionId" placeholder="请选择城市">
                <el-option v-for="(item,index) in regionList" :key="index" :label="item.region"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="regionId" label="类型">
              <el-select v-model="insertForm.sportTypeId" placeholder="请选择类型">
                <el-option v-for="(item,index) in sportTypeList" :key="index" :label="item.dictLabel"
                           :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="sex" label="性别">
              <el-select v-model="insertForm.sex">
                <el-option key="男" label="男" :value="1"></el-option>
                <el-option key="女" label="女" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="成绩" prop="score">
              <el-input v-model="insertForm.score" style="width: 150px" maxlength="6">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item class="itemWih" label="得分" prop="results">
              <el-input v-model="insertForm.results" style="width: 150px" maxlength="5">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item class="itemWih" label="提示语" prop="note">
              <el-input v-model="insertForm.note" style="width: 150px">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="addModule=false">取 消</el-button>
					<el-button type="primary" @click="insert('insertForm')">确 定</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getCalculator, insert, deleteCalculator, selectRegion} from "@/api/calculator";
/*import {cityList} from "@/api/public";*/
/*import {selectDictByType} from "@/api/dict";*/
import {insertData} from "../../api/calculator";

export default {
  name: "Calculator",
  data() {
    return {
      Cal: false,
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [],
      regionList: [],
      sportTypeList: [],
      tableData: [],
      pageTotal: null,
      addModule: false,//添加弹出层
      insertForm: {},
      query: {
        regionId: null,
        sportTypeId: null,
        pageIndex: 1,
        pageSize: 10
      }, rules: {
        regionId: [
          {required: true, message: '请选择城市', trigger: 'blur'},
        ],
        sportTypeId: [
          {required: true, message: '请选择类型', trigger: 'blur'},
        ],
        score: [
          {required: true, message: '请输入成绩', trigger: 'blur'},
        ],
        results: [
          {required: true, message: '请输入得分', trigger: 'blur'},
        ]
        , sex: [
          {required: true, message: '请选择性别', trigger: 'blur'},
        ], note: [
          {required: true, message: '请填写提示语', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    /**
     * 获取数据
     */
    getData() {
      getCalculator(this.query).then(val => {
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
        console.log(val)
      })
    },
    /**
     * 切换页面
     */
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },

    /**
     * 添加
     */
    async insert(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          insert(this.insertForm).then(val => {
            this.addModule = false;
            if (val.code == 200) {
              this.$message.success('添加成功');
              this.getData();
            } else {
              this.$message.error('添加失败！');
            }
          })
        }
      })
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗? ", {
        type: "warning"
      }).then(() => {
        deleteCalculator(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
            this.getData()
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },/*选中城市  渲染类型*/
    selectCity1(val) {
      this.query.sportTypeId = null
      if (JSON.stringify(this.regionList[val])) {
        this.sportTypeList = JSON.parse(JSON.stringify(this.regionList[val])).calculatorList
      }
    },
    // 重置
    reset() {
      this.query.regionId = ""
      this.query.sportTypeId = ""
      this.getData()
    },
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    handleRemove() {
      this.fileList = []
    },
    // 文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== 'xlsx') {
        this.fileList = []
        this.$message.warning('只能上传后缀是.xlsx的文件');
      }
      if (size > 10) {
        this.fileList = []
        this.$message.warning('文件大小不得超过10M');
      }
      if (extension == 'xlsx' && size < 10) {

        this.fileList.push(file.raw);
      }

    },
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        insertData(form).then(val => {
          console.log(val)
          if (val) {
            this.Cal = false
            this.$message.success('导入成功');
            this.fileList = []
            this.getData();
          } else if (val.code == 403) {
            this.$message.warning(val.message);
          } else {
            this.$message.warning('导入失败,');
          }
        })
      }
    },
  },

  created() {
    this.getData()
    // 城市下拉数据
    selectRegion().then(res => {
      if (res.code == 200) {
        console.log("code" + res.data)
        this.regionList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    });
  }
};
</script>

<style>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.mr10 {
  margin-right: 10px;
}

.code {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}
</style>